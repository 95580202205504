import * as React from 'react';
//import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/layout';

export default function CNAid({ pageContext, data }) {
  const cnaid = pageContext;
  const meta = {
    title: 'CN ' + cnaid.frontmatter.order,
    description: cnaid.frontmatter.cntitle,
  };
  console.log(cnaid);

  return (
    <Layout meta={meta} data={data}>
      <div className="cnaid-post title-container">
        <div className="titling-bkgd">
          <h1>
            CN {cnaid.frontmatter.order}: {cnaid.frontmatter.cntitle}
          </h1>
        </div>
      </div>
      <div className="cnaid-post content container py-5">
        <MDXRenderer>{cnaid.body}</MDXRenderer>
      </div>
    </Layout>
  );
}
